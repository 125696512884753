<template>
  <v-autocomplete
    v-if="!$isCreationRoute || showOnCreation"
    v-model="$v.owner.$model"
    data-cy="select-owner"
    :items="canSeeMembers ? orgMembers : [owner]"
    :label="$t('owner')"
    :filter="filterMembers"
    :menu-props="{ closeOnContentClick: true }"
    :error-messages="ownerErrors"
    :clearable="false"
    persistent-hint
    return-object
    item-text="name"
    item-value="username"
    open-on-clear
    hide-selected
    :required="isRequired"
    v-bind="{
      loading,
      disabled: !canSeeMembers || $attrs.disabled,
      readonly: !canSeeMembers || $attrs.readonly,
    }"
    :class="['owner-select', {
      'required-field': isRequired,
    }]"
    @blur="$v.owner.$touch()">
    <template #selection="data">
      <CyMember
        v-if="!_.isEmpty(data.item)"
        :key="`selection-${data.id}`"
        data-cy="selected-member"
        :member="data.item"/>
    </template>
    <template #item="data">
      <CyMember
        v-if="!_.isEmpty(data.item)"
        :key="`option-${data.id}`"
        data-cy="member-option"
        :member="data.item"/>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CySelectOwner',
  props: {
    currentOwner: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showOnCreation: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    owner: { required: requiredIf('isRequired') },
  },
  data: ({ currentOwner } = {}) => ({
    loading: true,
    owner: _.cloneDeep(currentOwner),
  }),
  computed: {
    ...mapGetters(['orgMembers']),
    ownerErrors () {
      const errors = []
      const { $dirty, required } = this.$v.owner
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    isRequired () {
      return !_.some([this.$attrs.readonly, this.$attrs.disabled]) && this.required
    },
    canSeeMembers () {
      return this.$cycloid.permissions.canDisplay('GetOrgMembers')
    },
  },
  watch: {
    owner (newVal) {
      this.$emit('input', newVal)
    },
    currentOwner (newVal) {
      this.owner = newVal
    },
  },
  async mounted () {
    if (this.canSeeMembers) await this.FETCH_AVAILABLE({ keyPath: 'members' })
    this.$toggle.loading(false)
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    filterMembers ({ username, given_name: firstName, family_name: lastName }, queryText) {
      return _.isEmpty(queryText) || _.some([
        username.toLowerCase().includes(queryText.toLowerCase()),
        firstName.toLowerCase().includes(queryText.toLowerCase()),
        lastName.toLowerCase().includes(queryText.toLowerCase()),
      ])
    },
  },
}
</script>

<style lang="scss" scoped>
.owner-select {
  ::v-deep .v-input {
    &__slot {
      min-height: 48px;
    }

    &__append-inner {
      align-self: center;
    }
  }
}
</style>
