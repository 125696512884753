<template>
  <div>
    <CyDetails
      :item-id="catalogRepositoryCanonical"
      :on-save="onSave"
      :on-cancel="onCancel"
      :on-delete="$toggle.showDeleteDialog"
      :can-cancel="canCancel"
      :can-save="canSave"
      :loading="isLoading"
      :saving="isSaving"
      :deleting="isDeleting"
      :is-owner="isOwner"
      :is-read-only="!canUpdateCatalogRepository">
      <template slot="details_form">
        <CyAlert
          data-cy="error-messages"
          theme="error"
          :content="[...refreshErrors, ...errors]"/>

        <v-text-field
          v-model="$v.formData.name.$model"
          :label="$t('forms.fieldName')"
          :error-messages="nameErrors"
          :disabled="!canUpdateCatalogRepository"
          required
          class="required-field"
          data-cy="name-input"
          @blur="$v.formData.name.$touch()"/>

        <CySelectOwner
          v-model="$v.formData.owner.$model"
          :readonly="!canUpdateCatalogRepository"
          :disabled="!canUpdateCatalogRepository"
          :current-owner="formData.owner"/>

        <CySearchBox
          v-model="$v.formData.url.$model"
          :error-messages="urlErrors"
          :disabled="isUrlReadOnly"
          :readonly="isUrlReadOnly"
          :hint="hints.urlGithub"
          :persistent-hint="!$isCreationRoute"
          required
          clearable
          class="required-field has-copy-btn mb-3"
          data-cy="url-input">
          <v-icon
            v-if="!$isCreationRoute && canUpdateCatalogRepository"
            slot="append-outer"
            color="darkgrey"
            @click="$toggle.isUrlLocked">
            {{ isUrlLocked ? 'lock' : 'lock_open' }}
          </v-icon>
          <span slot="label">{{ $t('untranslated.URL') }}</span>
          <CyCopyBtn
            v-if="!$v.formData.url.$invalid"
            slot="append"
            :copy-value="formData.url"/>
        </CySearchBox>

        <CyCredentials
          v-if="showCredentials"
          v-model="$v.formData.credential_canonical.$model"
          clearable
          :class="['mb-3', {
            'mt-5': !$isCreationRoute,
            'required-field': isPrivate,
          }]"
          :disabled="!canUpdateCatalogRepository"
          :error-messages="credentialCanonicalErrors"
          :hint="isPrivate ? $t('credentialRequiredForBranches') : null"
          :items="filteredCredentials"
          :label="$t('Credential')"
          :required="isPrivate"
          @blur="$v.formData.credential_canonical.$touch()"/>

        <v-row
          v-if="showBranches"
          no-gutters>
          <v-select
            v-model="$v.formData.branch.$model"
            :disabled="!hasBranches || !canUpdateCatalogRepository"
            :items="branches"
            :label="$t('forms.fieldBranch')"
            :hint="hints.fetchBranches"
            persistent-hint
            hide-selected
            required
            class="required-field align-self-start flex-1-1-0"
            data-cy="branch-select">
            <template #selection="{ item }">
              <div class="black--text">
                {{ item }}
              </div>
            </template>

            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>

          <CyTooltip
            right
            transition="slide-x-transition"
            :disabled="!canFetchBranches">
            <template #activator="{ on: refreshTooltip }">
              <span
                class="align-self-center ml-5"
                v-on="refreshTooltip">
                <CyButton
                  :readonly="!canFetchBranches"
                  :disabled="!canFetchBranches || !canUpdateCatalogRepository"
                  :loading="isFetchingBranches"
                  icon="refresh"
                  variant="secondary"
                  icon-only
                  sm
                  @click="getBranches"/>
              </span>
            </template>
            <span>
              {{ isFetchingBranches ? $t('refreshingBranches') : $t('refreshBranches') }}
            </span>
          </CyTooltip>
        </v-row>
      </template>

      <template slot="details_formAside">
        <div
          v-if="!_.isEmpty(catalogRepository)"
          class="stacks__container">
          <p v-html="$sanitizeHtml($tc('containsStacks', stacksCount, { stacksCount }))"/>
          <CyTagList
            small
            class="stacks__list"
            :tags="catalogRepository.service_catalogs">
            <template #tag="{ tag }">
              <router-link :to="{ name: 'stack', params: { stackRef: tag.ref } }">
                <CyTag
                  :key="tag.name"
                  variant="primary"
                  element-type="button"
                  data-cy="stacks-list-item">
                  {{ tag.name }}
                </CyTag>
              </router-link>
            </template>
          </CyTagList>
        </div>
      </template>
    </CyDetails>

    <portal to="header-actions">
      <div class="d-flex justify-end space-x-2">
        <v-menu
          v-if="!$isCreationRoute"
          bottom
          left
          offset-y
          :max-width="480"
          content-class="clone-helper-menu">
          <template #activator="{ on }">
            <CyButton
              prepend-icon="link"
              variant="secondary"
              theme="primary"
              v-on="on">
              <v-icon
                class="mr-1"
                :size="14">
                fas fa-terminal
              </v-icon>
              <span v-text="$t('stacks.howToClone')"/>
            </CyButton>
          </template>
          <div
            class="pa-4"
            @click.stop>
            <p>
              {{ $t('stacks.cloneInstructions') }}
              <a
                class="cy-link"
                rel="noopener"
                :href="$docLinks.catalogRepositories.create"
                target="_blank">
                {{ $t('learnMore') }}
              </a>
            </p>
            <CyTooltip
              :max-width="240"
              left>
              <template #activator="{ on }">
                <div
                  v-clipboard:copy="cloneCommand"
                  v-clipboard:success="() => SHOW_ALERT({ type: 'info', content: $t('commandCopied') })"
                  data-cy="clone-command"
                  class="command-snippet px-4 py-2 my-4"
                  v-on="on"
                  v-text="cloneCommand"/>
              </template>
              {{ $t('clickToCopy') }}
            </CyTooltip>
            <div
              v-if="formData.credential_canonical"
              class="h6"
              v-text="$t('Credential')"/>
            <div
              v-if="formData.credential_canonical"
              class="d-flex align-center mt-2">
              <CyIconCredential
                size="24"
                class="mr-2"
                type="ssh"/>
              <router-link
                class="cy-link"
                :to="{ name: 'credential', params: { credentialCanonical: formData.credential_canonical } }">
                {{ formData.credential_canonical }}
              </router-link>
            </div>
          </div>
        </v-menu>

        <CyTooltip
          v-if="!$isCreationRoute"
          v-has-rights-to="['RefreshServiceCatalogSource', catalogRepositoryCanonical]"
          :max-width="240"
          left>
          <template #activator="{ on }">
            <CyButton
              :loading="isRefreshingCR"
              icon="refresh"
              data-cy="refresh-button"
              v-on="on"
              @click="refreshCR">
              {{ $t('forms.btnRefresh') }}
            </CyButton>
          </template>
          {{ $t('refreshCRTooltip') }}
        </CyTooltip>
      </div>
    </portal>

    <CyModal
      v-if="refreshedRepository"
      modal-type="success"
      :cancel-btn-text="$t('forms.btnClose')"
      :header-title="`${$t('repoRefreshSuccess', { repo: refreshedRepository })}`"
      :cancel-btn-func="() => refreshedRepository = null"
      action-btn-hidden>
      <div v-if="repositoryStackChanges.length">
        <div class="d-flex justify-space-between">
          <div
            v-for="[typeOfChange, stacks] in repositoryStackChanges"
            :key="typeOfChange">
            <span class="font-weight-bold">
              {{ `${typeOfChange} ${_.toLower($tc('stackCount', stacks.length, { count: stacks.length }))}` }}
            </span>
            <CyTagList
              class="d-flex flex-column mt-2"
              :tags="stacks">
              <template #tag="{ tag }">
                <CyTag
                  :variant="stackVariant(typeOfChange)">
                  {{ tag.canonical }}
                </CyTag>
              </template>
            </CyTagList>
          </div>
        </div>
      </div>
      <p v-else>
        {{ $t('noStacksChanged') }}
      </p>
    </CyModal>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmDeleteHeader')"
      :action-btn-func="onDeleteConfirm"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
      <h3>{{ formData.name }}</h3>
      <p class="url">
        {{ formData.url }}
      </p>
      <p class="ma-0">
        {{ $t('confirmDeleteRepository') }}
      </p>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyCopyBtn from '@/components/copy-btn.vue'
import CyCredentials from '@/components/credentials.vue'
import CyDetails from '@/components/details.vue'
import CySearchBox from '@/components/search-box.vue'
import CySelectOwner from '@/components/select-owner.vue'
import CyTagList from '@/components/tag-list.vue'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb, checksPass, anyChecksPass } from '@/utils/helpers'
import { required, requiredIf } from 'vuelidate/lib/validators'

export const PUBLIC = 'PUBLIC'
export const PRIVATE = 'PRIVATE'

export default {
  name: 'CyPageCatalogRepository',
  components: {
    CyCopyBtn,
    CyCredentials,
    CyDetails,
    CySearchBox,
    CySelectOwner,
    CyTagList,
  },
  breadcrumb () {
    const header = this.$isCreationRoute
      ? this.$t('addCatalogRepository')
      : this.catalogRepository?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.catalogRepositories'),
        name: 'catalogRepositories',
      },
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('createCatalog'),
    }
  },
  props: {
    catalogRepositoryCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formData: {
      name: '',
      url: '',
      branch: '',
      credential_canonical: null,
      owner: {},
    },
    hasFetchedBranchesFor: {},
    isDeleting: false,
    isFetchingBranches: false,
    isLoading: true,
    isRefreshingCR: false,
    isSaving: false,
    isUrlLocked: true,
    showDeleteDialog: false,
    refreshedRepository: null,
    repositoryStackChanges: [],
  }),
  validations: {
    formData: {
      name: { required },
      url: {
        required,
        isValidGitUrl: (url) => REGEX.GIT.test(url),
      },
      branch: { required },
      credential_canonical: {
        required: requiredIf(function () {
          return this.isPrivate
        }),
        isValidCredential () {
          return !this.hasBranchesErrors
        },
      },
      owner: {},
    },
  },
  computed: {
    ...mapState('organization', {
      hasCredentials: (state) => !_.isEmpty(state.available.credentials),
    }),
    ...mapState('organization/catalogRepository', {
      branches: (state) => _.$get(state, 'branches', []),
      catalogRepositoryErrors: (state) => state.errors,
      refreshErrors: (state) => state.errors.refresh,
    }),
    ...mapGetters('organization', [
      'getCredentialsByType',
    ]),
    ...mapGetters('organization/catalogRepository', [
      'catalogRepository',
      'hasBranches',
      'hasBranchesErrors',
    ]),
    cloneCommand () {
      return `git clone --branch ${this.formData.branch} ${this.formData.url}`
    },
    canUpdateCatalogRepository () {
      const { catalogRepositoryCanonical } = this
      return this.$cycloid.permissions.canDisplay('UpdateServiceCatalogSource', catalogRepositoryCanonical)
    },
    showCredentials () {
      return this.formData.credential_canonical || (this.isPrivate && !this.$v.formData.url.$invalid)
    },
    isPrivate () {
      return _.isEqual(_.$get(this.hasFetchedBranchesFor, this.formData.url), PRIVATE)
    },
    errors () {
      const { branches, catalogRepository } = this.catalogRepositoryErrors
      return _.filter([
        ...branches,
        ...catalogRepository,
      ], ({ code }) => code !== 'Required')
    },
    hasErrors () {
      return !_.isEmpty(this.errors)
    },
    canCancel () {
      return this.$hasDataChanged('formData')
    },
    canSave () {
      return this.$isCreationRoute
        ? !this.$v.formData.$invalid
        : !this.$v.formData.$invalid && this.canCancel
    },
    showBranches () {
      return _.isString(this.hasFetchedBranchesFor[this.formData.url])
    },
    canFetchBranches () {
      const checks = {
        hasNoErrors: !this.hasErrors,
        hasValidURL: !this.$v.formData.url.$invalid,
        hasValidCred: !this.$v.formData.credential_canonical.$invalid,
        notFetchingBranches: !this.isFetchingBranches,
      }
      return checksPass(checks)
    },
    isUrlReadOnly () {
      return !this.$isCreationRoute && this.isUrlLocked
    },
    hints () {
      const { isUrlReadOnly, isFetchingBranches, hasBranches, isPrivate } = this
      return {
        urlGithub:
          isUrlReadOnly
            ? this.$t('fieldGitHintReadonly')
            : this.$t('fieldGitHint'),
        fetchBranches: (() => {
          if (isFetchingBranches) return this.$t('fetchingBranches')
          if (hasBranches) return this.$t('fetchBranchesSuccess')
          return isPrivate
            ? this.$t('fetchBranchesFieldsMissing.private')
            : this.$t('fetchBranchesFieldsMissing.public')
        })(),
      }
    },
    stacksCount () {
      return _.$get(this.catalogRepository, 'service_catalogs', []).length
    },
    stackVariant () {
      return (typeOfChange) => ({
        added: 'secondary',
        updated: 'primary',
        deleted: 'default',
      }[typeOfChange])
    },
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    urlErrors () {
      const errors = []
      const { $dirty, required, isValidGitUrl } = this.$v.formData.url
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isValidGitUrl) errors.push(this.$t('forms.fieldInvalidGitUrl'))
      return errors
    },
    credentialCanonicalErrors () {
      const errors = []
      const { $dirty } = this.$v.formData.credential_canonical
      if (!$dirty || this.isFetchingBranches) return errors
      if (this.hasBranchesErrors) errors.push(this.$t('fetchBranchesError'))
      return errors
    },
    crCanonical () {
      const { catalogRepositoryCanonical, formData: { name: catalogRepositoryName } } = this
      return this.$isCreationRoute ? this.$getSlug(catalogRepositoryName) : catalogRepositoryCanonical
    },
    filteredCredentials () {
      if (!this.hasCredentials) return []
      const creds = this.formData.url.startsWith('https')
        ? this.getCredentialsByType('basic_auth')
        : this.getCredentialsByType('ssh')
      return _.sortBy(creds, ({ name }) => _.lowerCase(name))
    },
    branchesWatcher () {
      return [
        this.formData.credential_canonical || '',
        this.formData.url || '',
      ]
    },
    isOwner () {
      return this.isEntityOwner(this.formContent)
    },
  },
  watch: {
    branchesWatcher: {
      async handler ([newCred], [oldCred]) {
        if (this.$hasDataChanged('formData.url') && _.isEqual(newCred, oldCred)) this.$set(this.formData, 'credential_canonical', null)
        this.SET_BRANCHES(null)
        await this.getBranches()
      },
      deep: true,
    },
    'formData.branch' () {
      this.CLEAR_CR_ERRORS()
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'credentials' })

    if (!this.$isCreationRoute) {
      await this.GET_CATALOG_REPOSITORY({ catalogRepositoryCanonical: this.crCanonical })
      this.setItemAndFormData()
      await this.getBranches()
    }

    this.$toggle.isLoading(false)
  },
  destroyed () {
    this.RESET_CR_STATE()
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/catalogRepository', [
      'CREATE_CATALOG_REPOSITORY',
      'DELETE_CATALOG_REPOSITORY',
      'GET_BRANCHES',
      'GET_CATALOG_REPOSITORY',
      'REFRESH_CATALOG_REPOSITORY',
      'UPDATE_CATALOG_REPOSITORY',
    ]),
    ...mapMutations('organization/catalogRepository', [
      'CLEAR_CR_ERRORS',
      'RESET_CR_STATE',
      'SET_BRANCHES',
    ]),
    async refreshCR () {
      if (this.isRefreshingCR) return

      this.$toggle.isRefreshingCR(true)
      const { formData: { name }, crCanonical: canonical } = this
      const res = await this.REFRESH_CATALOG_REPOSITORY({ canonical, name })
      if (res) {
        this.refreshedRepository = canonical
        this.repositoryStackChanges = Object.entries(res).filter(([_, changes]) => changes.length)
      }
      this.$toggle.isRefreshingCR(false)
    },
    async getBranches () {
      if (this.$v.formData.url.$invalid) return

      this.$toggle.isFetchingBranches(true)
      const { credential_canonical: credentialCanonical, url } = this.formData

      if (!_.has(this.hasFetchedBranchesFor, url)) this.$set(this.hasFetchedBranchesFor, url, PUBLIC)
      await this.GET_BRANCHES({ credentialCanonical, url })

      const markAsPrivate = anyChecksPass({
        noBranchesNoCred: !this.hasBranches && _.$isEmpty(credentialCanonical),
        hasBranchesAndCred: this.hasBranches && !_.$isEmpty(credentialCanonical),
      })
      if (markAsPrivate) this.$set(this.hasFetchedBranchesFor, url, PRIVATE)
      if (this.$isCreationRoute && this.branches.length === 1) this.formData.branch = this.branches[0]

      this.$toggle.isFetchingBranches(false)
    },
    onCancel () {
      this.$toggle.isUrlLocked(true)
      this.CLEAR_CR_ERRORS()
      this.$resetData('formData')
      this.$v.$reset()
    },
    async onSave () {
      if (this.isSaving) return

      this.$toggle.isSaving(true)

      const { crCanonical: catalogRepositoryCanonical, $router } = this
      const catalogRepository = {
        ...this.formData,
        canonical: catalogRepositoryCanonical,
        owner: this.formData?.owner?.username,
      }
      this.$isCreationRoute
        ? await this.CREATE_CATALOG_REPOSITORY({ catalogRepository, $router })
        : await this.UPDATE_CATALOG_REPOSITORY({ catalogRepository })

      this.$toggle.isSaving(false)

      if (this.hasErrors) return

      this.setItemAndFormData()
    },
    async onDeleteConfirm () {
      const { formData: catalogRepository, $router } = this

      this.$toggle.isDeleting(true)
      await this.DELETE_CATALOG_REPOSITORY({ catalogRepository, $router })
      this.$toggle.showDeleteDialog(false)
      this.$toggle.isDeleting(false)
    },
    setItemAndFormData () {
      if (!this.catalogRepository) return

      this.$set(this, 'formData', {
        ..._.cloneDeep(this.catalogRepository),
        branch: _.get(this.catalogRepository, 'branch', 'stacks'),
      })
      this.$setOriginalData()
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.catalogRepository',
        addCatalogRepository: 'Add catalog repository',
        clickToCopy: 'Click to copy to clipboard',
        commandCopied: 'The command has been copied to your clipboard.',
        confirmDeleteHeader: 'Delete catalog repository?',
        confirmDeleteRepository: `Please note that this does not delete the associated git repository. If you want to do that as well, you'll have to do it manually afterwards.`,
        confirmDeleteSentence: 'Are you sure that you want to delete this catalog repository?',
        containsStacks: 'Contains <strong>{stacksCount}</strong> stack: | Contains <strong>{stacksCount}</strong> stacks:',
        createCatalog: 'Create catalog repository',
        credentialRequiredForBranches: 'This field is required to fetch branch information.',
        fetchBranchesError: 'Valid credentials must be provided in order to fetch the branches.',
        fetchBranchesFieldsMissing: {
          public: 'Please supply a valid git URL before selecting a branch.',
          private: 'Please supply a valid git URL and credentials before selecting a branch.',
        },
        fetchBranchesSuccess: 'Branches fetched.',
        fetchingBranches: 'Fetching repository branches...',
        fieldGitHint: 'This field should contain a valid Git URL',
        fieldGitHintReadonly: 'This field is readonly because changing it could break something. If you still need to edit it, please click on the lock',
        noStacksChanged: 'No stack changes were found',
        refreshBranches: 'Refresh branches',
        refreshCRTooltip: 'For best performance, general stacks information is cached in database. Refreshing a catalog repository will update this cache.',
        refreshingBranches: 'Refreshing branches...',
        repoRefreshSuccess: '{repo} was refreshed succesfully',
      },
      es: {
        title: '@:routes.catalogRepository',
        addCatalogRepository: 'Añadir repositorio del catálogo',
        clickToCopy: 'Haga clic para copiar al portapapeles',
        commandCopied: 'El comando ha sido copiado a su portapapeles.',
        confirmDeleteHeader: 'Borrar repositorio del catálogo ?',
        confirmDeleteRepository: 'Ten en cuenta que esto no elimina el repositorio git asociado. Si quiere hacer eso también, tendrás que hacerlo manualmente después.',
        confirmDeleteSentence: 'Estás seguro de querer borrar este repositorio del catálogo?',
        containsStacks: 'Contiene <strong>{stacksCount}</strong> stack: | Contiene <strong>{stacksCount}</strong> stacks:',
        createCatalog: 'Crear repositorio de catálogo',
        credentialRequiredForBranches: 'Este campo es obligatorio para obtener la información de la sucursal.',
        fetchBranchesError: 'No se pueden recuperar ramas: la URL git o la credencial no son válidas.',
        fetchBranchesFieldsMissing: {
          public: 'Por favor, seleccione una URL git antes de seleccionar una rama.',
          private: 'Por favor, seleccione una URL git y credenciales validas antes de seleccionar una rama.',
        },
        fetchBranchesSuccess: 'Ramas obtenidas.',
        fetchingBranches: 'Obteniendo ramas del repositorio...',
        fieldGitHint: 'Este campo debe contener una URL Git válida',
        fieldGitHintReadonly: 'Este campo es de solo lectura porque cambiarlo podría romper algo. Si aún necesita editarlo, haga clic en el candado',
        noStacksChanged: 'No se encontraron cambios en el stack',
        refreshBranches: 'Actualizar ramas',
        refreshCRTooltip: 'Para un mejor rendimiento, la información general de los stacks se almacena en caché en la base de datos. Actualizar un repositorio de catálogo actualizará este caché.',
        refreshingBranches: 'Actualizando ramas...',
        repoRefreshSuccess: '{repo} se actualizó correctamente',
      },
      fr: {
        title: '@:routes.catalogRepository',
        addCatalogRepository: 'Ajouter source du catalogue',
        clickToCopy: 'Cliquez pour copier dans le presse-papier',
        commandCopied: 'La commande a été copiée dans votre presse-papiers.',
        confirmDeleteHeader: 'Supprimer sources du catalogue ?',
        confirmDeleteRepository: 'Veuillez noter que cela ne supprime pas le dépôt git associé. Si vous souhaitez le faire également, vous devrez le faire manuellement par la suite.',
        confirmDeleteSentence: 'Êtes-vous sûr de vouloir supprimer ces sources du catalogue ?',
        containsStacks: 'Contient <strong>{stacksCount}</strong> stack: | Contient <strong>{stacksCount}</strong> stacks:',
        createCatalog: 'Créer sources du catalogue',
        credentialRequiredForBranches: `Ce champ est obligatoire pour récupérer les informations de la branche.`,
        fetchBranchesError: `Impossible de récupérer les branches: les informations d'identification ou l'URL du dépot ne sont pas valides.`,
        fetchBranchesFieldsMissing: {
          public: 'Veuillez sélectionner une URL git valide de sélectionner une branche.',
          private: `Veuillez sélectionner une URL git valide et des informations d'identification avant de sélectionner une branche.`,
        },
        fetchBranchesSuccess: 'Des branches sont allées chercher.',
        fetchingBranches: 'Récupération des branches du sources du catalogue...',
        fieldGitHint: 'Ce champ doit contenir une URL Git valide',
        fieldGitHintReadonly: `Ce champ est en lecture seule car le changer pourrait casser quelque chose. Si vous avez besoin de l'éditer, veuillez cliquer sur le cadenas`,
        noStacksChanged: `Aucun changement de stack n'a été trouvé`,
        refreshBranches: 'Actualiser les branches',
        refreshCRTooltip: `Pour de meilleures performances, les informations générales sur les stacks sont mises en cache dans la base de données. L'actualisation d'un dépot de catalogue mettra à jour ce cache.`,
        refreshingBranches: 'Actualisation des branches...',
        repoRefreshSuccess: '{repo} a été rafraîchie avec succès',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.has-copy-btn ::v-deep .v-label {
  height: auto;
}

.url {
  color: cy-get-color("grey", "dark-2");
}

.stacks__list ::v-deep .tag-list__item {
  width: 100%;
  margin: 4px auto;
}

.v-toolbar__content {
  line-height: 62px;
}

.clone-helper-menu {
  .command-snippet {
    border-radius: 4px;
    background-color: cy-get-color("black");
    color: cy-get-color("white");
    font-family: $font-family-code;
    cursor: pointer;
  }
}
</style>
