import { render, staticRenderFns } from "./select-owner.vue?vue&type=template&id=3248aa3b&scoped=true"
import script from "./select-owner.vue?vue&type=script&lang=js"
export * from "./select-owner.vue?vue&type=script&lang=js"
import style0 from "./select-owner.vue?vue&type=style&index=0&id=3248aa3b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3248aa3b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
